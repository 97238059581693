.App {
  text-align: center;
}

.separator {
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
  color: #fff;
  background-color: #000;
}
.stried {
  background-color: #ddd;
}
.campodatos {
  background-color: #f6f7f9;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #ddd;
  padding: 8px;
}
.react-calendar__tile--active {
  background-color: #000 !important;
}
.react-calendar__tile--now {
  background-color: #f6f7f9;
}
.campodatoscomment {
  background-color: #f6f7f9;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  padding: 8px;
}
.camporesultados {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid #ddd;
  padding: 8px;
}
.camporesultados > div {
  text-align: right;
}

.campocomments {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid #ddd;
  padding: 8px;
}
.campocomments > div {
  text-align: justify;
}
.campodatos > div {
  text-align: left;
}
.fecha_select {
  height: 38px;
  display: block;
  width: 100%;
  /* padding: .375rem 2.25rem .375rem .75rem; */
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* -webkit-appearance: none; */
  appearance: none;
}
.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 5px;
  border: 0px solid gray !important;
}

/* loading */

.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #000;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
